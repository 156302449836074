<template>
    <div>

    <el-main style=" padding-top: 5px;padding-bottom: initial  ">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-row>
                        <el-col :lg="24">
                            <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                                <el-row>
                                    <el-col :lg="24" style="line-height: 27px;">
                                        <strong style=" font-size: 16px; ">付款单</strong>
                                    </el-col>
                                </el-row>
                            </div>
                            <el-row>

                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-radio-group v-model="radio" style=" margin-top: 14px; ">
                                        <el-radio :label="0">付款单</el-radio>
                                        <el-radio :label="1">预付款</el-radio>
                                    </el-radio-group>
                                </el-col>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-form-item label="录单日期:">
                                        <el-date-picker
                                                v-model="startmonth"
                                                align="right"
                                                type="date"
                                                size="mini"
                                                placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-form-item label="单据编号:">
                                        <el-input  size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-form-item label="收款单位:">
                                        <el-input  size="mini">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-form-item label="经手人:">
                                        <el-input  size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-form-item label="摘要:">
                                        <el-input  size="mini"></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-form-item label="附加说明:">
                                        <el-input  size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
<el-row>
    <el-col :lg="24">
        <vxe-grid
                  border
                  resizable
                  show-overflow
                  keep-source
                  ref="xGrid"
                  size="mini"
                  height="250"
                  highlight-hover-row
                  :print-config="{}"
                  :data="tableDataMain"
                  :columns="tableColumn"
                  :mouse-config="{selected: false}"
                  :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                  :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
        </vxe-grid>
        <vxe-grid
                border
                resizable
                show-overflow
                keep-source
                ref="xGrid"
                size="mini"
                height="250"
                highlight-hover-row
                :print-config="{}"
                :data="footerData"
                :columns="footerTableColumn"
                :mouse-config="{selected: false}"
                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
        </vxe-grid>

        <template #category_edit="{ row}"><!-- 类别 -->
            <el-select v-model="row.category" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询" clearable  >
                <el-option v-for="item in category" :key="item.id" :label="item.category" :value="item.category">
                    <span style="margin:15px;">{{ item.category}}</span>
                </el-option>
            </el-select>
        </template>
        <template #specification_edit="{ row}"><!-- 实际规格 -->
            <el-select v-model="row.specification" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询" clearable filterable >
                <el-option v-for="item in specification" :key="item.id" :label="item.specification" :value="item.specification">
                    <span style="margin:15px;">{{ item.specification}}</span>
                </el-option>
            </el-select>
        </template>
        <template #unit_edit="{ row}"><!-- 单位 -->
            <el-select v-model="row.unit" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询" clearable  >
                <el-option v-for="item in unit" :key="item.id" :label="item.unit" :value="item.unit">
                    <span style="margin:15px;">{{ item.unit}}</span>
                </el-option>
            </el-select>
        </template>
    </el-col>
</el-row>
                    <el-row>
                        <el-col :lg="4" :sm="12" :md="8">
                            <el-form-item label="结算金额:">
                                <el-input  size="mini" value="100"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="4" :sm="12" :md="8">
                            <el-form-item label="可分配金额:" label-width="100px">
                                <el-input  size="mini"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="4" :sm="12" :md="8">
                            <el-form-item label="合计:">
                                <el-input  size="mini"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :lg="4" :sm="12" :md="8">
                            <el-form-item label="">
                                <el-input  size="mini" value="壹佰圆"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="2" style="margin-left: 20px">
                            <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" >收款情况</el-button>
                        </el-col>
                        <el-col :lg="2" :sm="12" :md="8">
                            <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini">检查勾单</el-button>
                        </el-col>
                        <el-col :lg="2" :sm="12" :md="8">
                            <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" >自动分配</el-button>
                        </el-col>
                        <el-col :lg="2" :sm="12" :md="8">
                            <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" >立即保存</el-button>
                        </el-col>
                        <el-col :lg="2" :sm="12" :md="8">
                            <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" >立即打印</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'

    export default {

        data() {
            return {
                radio: 0,
                startmonth:'',
                clearingFormList: [{
                    value: 1,
                    label: '欠款'
               }, {
                    value: 2,
                    label: '现金'
               }, {
                    value: 3,
                    label: '微信'
               }, {
                    value: 4,
                    label: '汇款+现金'
               }],
                clearing_form: 1,
                otherExpensesList:[{
                    value: 1,
                    label: '运费'
               }, {
                    value: 2,
                    label: '风割费'
               }, {
                    value: 3,
                    label: '装车费'
               }, {
                    value: 4,
                    label: '磅费'
               }],
                other_expenses :'',
                startDate:'',
                tableColumn: [
                    { type: 'seq', title: '序号', width: 40},
                    { field:'account',width: 122 , title: '账户编码', editRender: { autofocus:'input.el-input__inner'}},
                    { field:'monetary',width: 122 , title: '账户名称',  editRender: { autofocus:'input.el-input__inner'}},
                    { field:'remark',width: 150 , title: '金额',  editRender: { autofocus:'input.el-input__inner'}},
                    { field:'check_number',width: 120 , title: '支票号码',  editRender: { autofocus:'input.el-input__inner'}},
                    { field:'check_date',width: 120, title: '支票日期', editRender: { name: 'ElInputNumber',props: {controls:false}}},
                    { field:'drawerUnits',width: 120, title: '出票单位', editRender: { name: 'ElInputNumber',props: {controls:false}}},
                    { field:'drawerUnits',width: 120, title: '对冲金额', editRender: { name: 'ElInputNumber',props: {controls:false}}},
                    { field:'drawerUnits',width: 120, title: '备注', editRender: { name: 'ElInputNumber',props: {controls:false}}},
                ],
                tableDataMain : [],
                footerData:[],
                footerTableColumn: [
                    { field:'monetary',width: 122 , title: '类型',  editRender: { autofocus:'input.el-input__inner'}},
                    { field:'account',width: 122 , title: '单据编号', editRender: { autofocus:'input.el-input__inner'}},
                    { field:'check_number',width: 120 , title: '发生日期',  editRender: { autofocus:'input.el-input__inner'}},
                    { field:'check_date',width: 120, title: '金额/加工费', editRender: { name: 'ElInputNumber',props: {controls:false}}},
                    { field:'drawerUnits',width: 120, title: '余额', editRender: { name: 'ElInputNumber',props: {controls:false}}},
                    { field:'drawerUnits',width: 120, title: '结算金额', editRender: { name: 'ElInputNumber',props: {controls:false}}},
                    { field:'drawerUnits',width: 120, title: '确认', editRender: { name: 'ElInputNumber',props: {controls:false}}},
                ],
                unit:[
                    {id:1,unit:'吨'},
                    {id:2,unit:'支'}
                ],
                category:[
                    {id:1,category:'方管'},
                    {id:2,category:'矩管'}
                ],
                specification:[
                    {id:1,specification:'60*40*4*6米'},
                    {id:2,specification:'200*100*4*6米'},
                    {id:3,specification:'200*100米4*12米'},
                    {id:4,specification:'150*100*4*6米'},
                    {id:5,specification:'50*25*3*6米'},
                    {id:6,specification:'50*50*3.5*6米'},
                    {id:7,specification:'160*60*6*6米'},
                    {id:8,specification:'200*100*6*12米'},
                    {id:9,specification:'250*250*12*12米'},
                    {id:10,specification:'60*60*6*米'},
                    ],
                form: {
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: ''
               },
                activeName:'first',
                checked: false,
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '测试数据',
               }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '测试数据'
               }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '测试数据',
               }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '测试数据'
               }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '测试数据'
               }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '测试数据'
               }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '测试数据'
               }],
                regionList: [],
                restaurants: [
                    { value: '前端', name: '前端'},
                    { value: '后端', name: '后端'}
                ],
                sexList: [
                    { label: '', value: ''},
                    { label: '男', value: '1'},
                    { label: '女', value: '0'}
                ],

           }
       },
        methods: {
            editMethod ({ row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate (value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName ({ rowIndex}) {
                if (rowIndex%2 === 1) {
                    return 'success-row';
               } else{
                    return '';
               }
           },
            onSubmit() {
                //console.log('submit!');
           }
       },computed: {
            // 计算属性的 getter
            billName: function () {
                // `this` 指向 vm 实例
                if(this.clearing_form === 1){
                    return '欠款单';
               }else {
                    return '调拨单';
               }
           }
       },
        created () {
            var list = [];
            for (var index = 0; index < 30; index++) {
                list.push({
                    id: index,
                    account: "",
                    monetary: "",
                    remark: "",
                    check_number : "",
                    check_date : "",
                    drawerUnits: ""
               });
           }
            this.loading = true
            this.tableDataMain = list;
       }
   };


</script>

<style scoped>
    .input-with-select .el-input .el-input--mini .el-input-group .el-input-group--prepend .el-input-group__prepend{
        width: 80px !important;
   }
    .vxe-table--render-default .vxe-cell{
        padding-left:initial !important;
        padding-right: initial !important;
   }
    .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width: 128px !important;
   }
    .el-form-item__content .el-input-group{
        vertical-align: initial;
   }

</style>
